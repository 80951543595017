header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    z-index: 3;
    background-color: #e6e6e6 87;
    border-bottom: solid white 1px;
    transition: 0.2s ease;
}
header div {
    margin: 10px 5%;
}
header .middle span {
    margin-left: 10px;
    cursor: pointer;
}
header .control {
    display: flex;
    justify-content: center;
    align-items: center;
}
header .control span.image {
    font-size: 38px;
    margin-right: 20px;
}
header .control i {
    font-size: large;
    color: var(--blue);
    display: inline-block;
    margin-left: 10px;
    position: relative;
}
header .control i .menu {
    display: none;
}
header .control i:hover {
    cursor: pointer;
}
header .control i:hover .menu, header .control i.open .menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    position: absolute;
    padding: 20px;
    background-color: white;
    border: 1px solid var(--blue);
    box-shadow: 5px 5px 0 #055df5 7b;
    border-radius: 20px;
    top: 10px;
}
header .control i:hover .menu span, header .control i.open .menu span {
    font-size: 16px;
    color: black;
    font-family: "Metropolis-Regular";
    display: flex;
    justify-content: center;
    align-items: center;
}
header .control i:hover .menu span svg, header .control i.open .menu span svg {
    margin-right: 3px;
}
header .control i:hover .menu span:hover, header .control i.open .menu span:hover {
    color: var(--blue);
}
header .control i:hover .menu .profile, header .control i.open .menu .profile {
    margin-bottom: 10px;
    border-bottom: solid 1px var(--blue);
    padding-bottom: 10px;
}
header .control a {
    display: inline-block;
    margin-left: 20px;
    color: var(--blue);
    text-decoration: none;
}
@media (min-width: 250px) and (max-width: 480px) {
    .logo {
        display: none;
   }
}
