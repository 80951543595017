.container .title {
    color: var(--blue);
    font-size: 32px;
    margin-top: 100px !important;
}
.course-list {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
}
hr {
    margin-bottom: 40px;
}
.platform {
    background-color: white;
    display: inline-block;
    padding: 10px;
    border: var(--blue) 1px solid;
    border-radius: 10px;
}