.login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 100px;
    background-color: var(--main-background);
    height: 100vh;
    z-index: -1;
}
.login .login-card {
    background-color: white;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 0;
    border: solid 1px var(--blue);
    padding: 20px 50px;
    border-radius: 20px;
    box-shadow: 5px 5px 0 var(--blue);
    z-index: 2;
}
.login .login-card h3 {
    align-self: center;
    color: var(--blue);
    margin-bottom: 30px;
}
.login .login-card a {
    align-self: center;
}
.login .login-card .login-btn {
    background-color: white;
    border: solid 1px var(--blue);
    box-shadow: 5px 5px 0 var(--blue);
    border-radius: 10px;
    padding: 10px 30px;
    margin-top: 20px;
    align-self: center;
}
.login .login-card div {
    margin-bottom: 20px;
}
.login .welcome {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: flex-start;
}
.login .welcome span {
    color: var(--gray-2);
    font-size: 32px;
    font-family: "Metropolis-Bold";
}
.login .welcome .title {
    color: var(--blue);
}
.login .control {
    margin-top: 100px;
}
.login .pattrens {
    position: fixed;
    bottom: 20px;
    right: -100px;
    width: 40%;
}
@media (min-width: 200px) and (max-width: 1300px) {
    .login {
        grid-template-columns: 1fr;
        padding: 20px;
   }
    .login .pattrens {
        display: none;
   }
}
