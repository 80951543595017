html {
    scroll-behavior: smooth;
  }
  
  /* Import font */
  @font-face {
    font-family: "Metropolis-Black";
    src: url(/src/assets/fonts/Metropolis-Black.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-BlackItalic";
    src: url(/src/assets/fonts/Metropolis-BlackItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-Bold";
    src: url(/src/assets/fonts/Metropolis-Bold.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-BoldItalic";
    src: url(/src/assets/fonts/Metropolis-BoldItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-ExtraBold";
    src: url(/src/assets/fonts/Metropolis-ExtraBold.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-ExtraBoldItalic";
    src: url(/src/assets/fonts/Metropolis-ExtraBoldItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-ExtraLight";
    src: url(/src/assets/fonts/Metropolis-ExtraLight.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-ExtraLightItalic";
    src: url(/src/assets/fonts/Metropolis-ExtraLightItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-Light";
    src: url(/src/assets/fonts/Metropolis-Light.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-LightItalic";
    src: url(/src/assets/fonts/Metropolis-LightItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-Medium";
    src: url(/src/assets/fonts/Metropolis-Medium.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-MediumItalic";
    src: url(/src/assets/fonts/Metropolis-MediumItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-Regular";
    src: url(/src/assets/fonts/Metropolis-Regular.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-RegularItalic";
    src: url(/src/assets/fonts/Metropolis-RegularItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-SemiBold";
    src: url(/src/assets/fonts/Metropolis-SemiBold.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-SemiBoldItalic";
    src: url(/src/assets/fonts/Metropolis-SemiBoldItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-Thin";
    src: url(/src/assets/fonts/Metropolis-Thin.otf) format("opentype");
  }
  @font-face {
    font-family: "Metropolis-ThinItalic";
    src: url(/src/assets/fonts/Metropolis-ThinItalic.otf) format("opentype");
  }
  
  :root {
    /* Primary colors */
    --blue: #1a8cf7;
    --dark-blue: #010337;
    --darker-blue: #181b28;
  
    /* Secondary Colors */
    --s-blue: #055cf5;
    --s-red: #f4035a;
    --s-cayn: #21cbdd;
    --s-green: #5be498;
    --s-orange: #ffc63c;
    --main-background: #f2f2f2;
  
    /* Gray colors */
    --gray-1: #3c3c3b;
    --gray-2: #575756;
    --gray-3: #c6c6c6;
    --gray-4: #e5e5e5;
  }
  
  * {
    font-family: "Metropolis-Medium";
    text-decoration: none !important;
  }
  
  body {
    background-color: var(--main-background) !important;
    padding: 0;
  }
  
  *::selection {
    background-color: var(--blue);
    color: #fff;
  }
  