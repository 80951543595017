.wrapper { 
  margin-top: 85px;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

[data-marker="*"] > .fa-li > .fa-circle {
  cursor: pointer;
  font-size: 40%;
  vertical-align: 40%;
}
