#content.p-4 {
  margin: 0 !important;
  padding: 0 !important;
}
#content.ml-5 {
  margin: 0 !important;
  padding: 0 !important;
}
#content.p-md-5 {
  margin: 0 !important;
  padding: 0 !important;
}
#content.pt-5 {
  margin: 0 !important;
  padding: 0 !important;
}


.course-player {
  margin-left: 100px;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#sidebar ul li a { 
  color: var(--gray-2);
}

#sidebar ul li a:hover { 
  color: black;
}

.navigation-list { 
  display: flex;
  margin-top: 40px;
}

.navigation-list div { 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--blue);
  width: 50px;
  height: 50px;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
}

.navigation-list div.current { 
  box-shadow: 5px 5px 0 var(--blue);
}