.logout:hover {
  color: black;
  cursor: pointer;
}

.list-unstyled.components li {
  color: black;
}

#sidebar {
  background-color: white;
  border: 1px solid rgb(179, 179, 179);
  box-shadow: 5px 5px 0 var(--blue);
  border-radius: 20px;
  position: fixed;
  z-index: 100;
}

.contact {
  color: var(--blue);
}

#sidebar ul li a {
  color: black;
}

#sidebar {
  background-color: white;
  border: 1px solid rgb(179, 179, 179);
  box-shadow: 5px 5px 0 var(--blue);
  border-radius: 20px;
  margin-top: 20px;
}

#sidebar .week-title {
  color: rgb(114, 114, 114);
}

#sidebar .week-list {
  color: black;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
}

#sidebar .week-list:hover {
  color: var(--blue);
}

#sidebar .custom-menu .btn.btn-primary:after {
  left: 40px !important;
  right: -1px !important;
  transform: rotate(0deg) !important;
}
