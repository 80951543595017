.center-week { 
    display: flex;
    justify-content: center;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
    width: 600px !important;
    margin-left: 20px;
}

.center-week h1, h2, ul {
    align-self: flex-start;
}
