.course-card {
  border-radius: 10px;
  border: solid 1px var(--gray-3);
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: fit-content;
  background-color: white;
  padding: 0 0 10px 0;
  width: 90%;
  max-width: 350px;
  transition: 0.2s ease;
  margin-top: 30px;
  margin-right: 20px;
  box-shadow: 5px 5px 0 var(--s-blue);
  border-radius: 10px;
}
.course-card:hover {
  box-shadow: 0px 0px 0 var(--s-orange) !important;
  cursor: pointer;
  transition: 0.2s ease;
  transform: translateX(5px) translateY(5px) !important;
}
.card-img {
  background-size: cover !important;
  background-position: center;
  padding: 15px;
  color: var(--s-orange);
  height: 130px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px 10px 0 0 !important;
}
.level {
  display: inline-block;
  margin-right: 10px;
}
.duration-level {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.duration-level span {
  font-family: "Metropolis-Light";
}
.course-card.closed span.title {
  color: var(--gray-3) !important;
}
.course-card.closed .status {
  color: black;
  background-color: var(--gray-3) !important;
}
.card-img span.title {
  font-family: "Metropolis-bold";
  position: absolute;
  bottom: 5px;
  padding-right: 16px;
  font-size: larger;
}
.details {
  padding: 10px 15px;
  font-size: 12px;
}
.details div {
  display: flex;
  align-items: center;
}
.details img {
  margin-right: 10px;
}
.details span {
  text-transform: capitalize;
  margin-left: 5px;
}
a {
  text-decoration: none !important;
  color: black;
}
.course-card.last {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 198px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .course-list {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    border: var(--blue) 2px solid;
    align-items: center;
    padding: 20px;
 }
}
